import * as React from "react";

function SvgComponent(props) {
  return (
  <svg version="1.1" id="Layer_1"  x="0px" y="0px"
  viewBox="0 0 1156.2 163">
  <path id="XMLID_8_" d="M296.7,9.9l9.9-9.9l1.7,1.7l-9.9,9.9L296.7,9.9z"/>
  <g id="XMLID_1_">
    <path id="XMLID_2_" d="M7.8,130.8c9.5,0.2,13.5,5.1,13.5,12.9c0,8.1-4.4,13.2-13.7,13.2H0v-26C0.6,130.8,5.6,130.8,7.8,130.8z
		 M5.4,152.6h2.4c4.5,0,8.1-2.4,8.1-8.9c0-6.1-3.1-8.6-8-8.6H5.4V152.6z"/>
    <path id="XMLID_5_" d="M31.1,135.6H26v-4.8h5.1V135.6z M31,156.9h-5v-18.6h5V156.9z"/>
    <path id="XMLID_9_" d="M36.3,138.2h5.1v4.4c0.9-2.8,3-4.8,5.9-4.8c0.3,0,0.7,0,0.8,0.1v5c-0.2-0.1-0.5-0.1-0.8-0.1
		c-4.1-0.4-5.7,1.3-5.7,3.8v10.2h-5.3V138.2z"/>
    <path id="XMLID_13_" d="M60.2,153.4c1.8,0,3.2-0.7,3.8-2.5h4.7c-0.6,4-4.5,6.3-8.4,6.3c-5.5,0-9.3-3.7-9.3-9.5c0-5.6,3.3-9.9,9-9.9
		s8.7,3.9,8.7,9.5v1.4H56.2C56.1,151.6,57.6,153.4,60.2,153.4z M63.8,145.6c0-2.3-1.2-4.1-3.8-4.1c-2.4,0-3.8,2-3.8,4.1H63.8z"/>
    <path id="XMLID_38_" d="M89.1,145h-4.4c-0.2-1.5-1.3-3.2-3.4-3.2c-2.4,0-4.1,1.9-4.1,5.7c0,3.6,1.6,5.9,4.2,5.9
		c2.1,0,3.2-1.9,3.4-3.1h4.3c-0.2,3.9-3.2,6.9-7.8,6.9c-5.2,0-9-3.6-9-9.6c0-5.8,3.6-9.7,9-9.7C85.9,137.9,88.8,141,89.1,145z"/>
    <path id="XMLID_43_" d="M100,141.9v9.9c0,1.4,0.5,1.5,1.7,1.5h2.5v3.2c-0.5,0.2-1.4,0.5-3.3,0.5c-4.9,0-6.2-2-6.2-5v-10.1h-3v-3.7
		h3.2l1.3-5.6h3.7v5.6h4.1v3.7L100,141.9L100,141.9z"/>
    <path id="XMLID_46_" d="M107,147.7c0-5.9,3.5-9.8,9-9.8s9,3.8,9,9.8c0,5.6-3.3,9.6-9,9.6C110.5,157.2,107,153.5,107,147.7z
		 M119.8,147.5c0-3-0.9-5.7-3.8-5.7c-2.8,0-3.9,2.6-3.9,5.7c0,2.9,0.9,5.8,3.9,5.8C118.8,153.3,119.8,150.6,119.8,147.5z"/>
    <path id="XMLID_55_" d="M129.2,138.2h5.1v4.4c0.9-2.8,3-4.8,5.9-4.8c0.3,0,0.7,0,0.8,0.1v5c-0.2-0.1-0.5-0.1-0.8-0.1
		c-4.1-0.4-5.7,1.3-5.7,3.8v10.2h-5.3V138.2L129.2,138.2z"/>
    <path id="XMLID_57_" d="M152.3,147.7c0-5.9,3.5-9.8,9-9.8s9,3.8,9,9.8c0,5.6-3.3,9.6-9,9.6C155.9,157.2,152.3,153.5,152.3,147.7z
		 M165.2,147.5c0-3-0.9-5.7-3.8-5.7c-2.8,0-3.9,2.6-3.9,5.7c0,2.9,0.9,5.8,3.9,5.8C164.1,153.3,165.2,150.6,165.2,147.5z"/>
    <path id="XMLID_60_" d="M185.3,141.9h-3.9v15h-5.3v-15h-3.4v-3.7h3.4v-2.7c0-3,1.9-4.7,5.1-4.7h4.3v3.5H183c-1.3,0-1.8,0.6-1.8,2.1
		v1.8h4L185.3,141.9L185.3,141.9z"/>
    <path id="XMLID_62_" d="M198.4,130.8h9.6c5.7,0,10,3,10,8.5c0,5.2-3.7,8.2-9.3,8.2h-5v9.3h-5.3V130.8L198.4,130.8z M208.5,143.5
		c2.6,0,4.5-1.9,4.5-4.4c0-2.6-2-4.2-4.6-4.2h-4.8v8.7h4.9V143.5z"/>
    <path id="XMLID_65_" d="M221.8,130.1h5.2v10.8c0.9-1.3,2.4-2.8,5.4-2.8c3.6,0,6.5,2.1,6.5,5.9v12.8h-5.3v-12c0-1.7-1.4-2.6-3.1-2.6
		s-3.4,0.9-3.4,2.8v11.8h-5.3L221.8,130.1L221.8,130.1z"/>
    <path id="XMLID_67_" d="M242.9,147.7c0-5.9,3.5-9.8,9-9.8s9,3.8,9,9.8c0,5.6-3.3,9.6-9,9.6C246.4,157.2,242.9,153.5,242.9,147.7z
		 M255.8,147.5c0-3-0.9-5.7-3.8-5.7c-2.8,0-3.9,2.6-3.9,5.7c0,2.9,0.9,5.8,3.9,5.8C254.7,153.3,255.8,150.6,255.8,147.5z"/>
    <path id="XMLID_70_" d="M271.7,141.9v9.9c0,1.4,0.5,1.5,1.7,1.5h2.5v3.2c-0.5,0.2-1.4,0.5-3.3,0.5c-4.9,0-6.2-2-6.2-5v-10.1h-3
		v-3.7h3.2l1.3-5.6h3.7v5.6h4.1v3.7L271.7,141.9L271.7,141.9z"/>
    <path id="XMLID_72_" d="M278.7,147.7c0-5.9,3.5-9.8,9-9.8s9,3.8,9,9.8c0,5.6-3.3,9.6-9,9.6C282.2,157.2,278.7,153.5,278.7,147.7z
		 M291.6,147.5c0-3-0.9-5.7-3.8-5.7c-2.8,0-3.9,2.6-3.9,5.7c0,2.9,0.9,5.8,3.9,5.8C290.5,153.3,291.6,150.6,291.6,147.5z"/>
    <path id="XMLID_75_" d="M299.2,158.4c0-2.5,3.3-3.3,3.6-3.5c-0.6-0.3-2.3-1-2.3-2.8c0-1.3,1.2-2.6,3.8-3c-2.5-1.1-3.8-3.3-3.8-5.4
		c0-3.7,3.8-5.8,8.8-5.8c2.6,0,4,0.6,5.4,1.5c1.1-0.4,3.9-1.5,5.5-2.2v4.1l-3.2,0.5c0.3,0.7,0.4,1.5,0.4,2c0,3.2-2.9,6.2-8.4,6.2
		c-0.4,0-1.4,0-1.8-0.1c-1.4,0.1-1.9,0.4-1.9,0.8c0,0.6,1.1,0.7,3.3,0.8c0.8,0,2.2,0.1,3.9,0.2c4.6,0.3,6.8,2.4,6.8,5.3
		c0,3.5-3.3,6-9.9,6C302.7,163,299.2,161.2,299.2,158.4z M314.2,158c0-1.1-1-1.9-2.8-2l-4.3-0.3c-1.7-0.1-2.8,1-2.8,2.1
		c0,1.6,1.8,2.3,5.8,2.3C313.1,160.1,314.2,159.3,314.2,158z M312.5,143.9c0-1.9-1.3-3.2-3.3-3.2s-3.3,1.3-3.3,3.2s1.3,3,3.3,3
		C311.2,147,312.5,145.8,312.5,143.9z"/>
    <path id="XMLID_79_" d="M323.9,138.2h5.1v4.4c0.9-2.8,3-4.8,5.9-4.8c0.3,0,0.7,0,0.8,0.1v5c-0.2-0.1-0.5-0.1-0.8-0.1
		c-4.1-0.4-5.7,1.3-5.7,3.8v10.2h-5.3V138.2z"/>
    <path id="XMLID_81_" d="M338.2,151.5c0-4.1,2.9-6.2,9.5-6.3h2.3v-1.1c0-1.7-1-2.7-3.1-2.7c-1.5,0-3.1,0.6-3.6,2.4h-4.4
		c0.2-4,3.6-5.9,8.2-5.9c5.7,0,7.9,2.2,7.9,5.9v13.1h-4.4l-0.4-3.2c-1.3,2.7-3.2,3.6-5.8,3.6C341,157.2,338.2,155.2,338.2,151.5z
		 M350.1,150.6v-2.8h-1.9c-2.6,0-5,0.8-5,3.1c0,1.7,1.4,2.8,3.1,2.8C348.2,153.8,350.1,152.2,350.1,150.6z"/>
    <path id="XMLID_84_" d="M360,138.2h5.3v2.7c1.1-1.7,3.2-3,5.8-3c4.2,0,7.2,3,7.2,9.5c0,6.8-3.3,9.8-7.7,9.8c-2.4,0-4.3-1.4-5.3-3.2
		v8.6H360V138.2z M373.4,147.4c0-3.6-1.6-5.7-4-5.7c-2.7,0-4,2.5-4,5.7c0,3.1,1.3,5.9,4,5.9C371.6,153.4,373.4,151.6,373.4,147.4z"
    />
    <path id="XMLID_88_" d="M382.5,130.1h5.2v10.8c0.9-1.3,2.4-2.8,5.4-2.8c3.6,0,6.5,2.1,6.5,5.9v12.8h-5.3v-12c0-1.7-1.4-2.6-3.1-2.6
		s-3.4,0.9-3.4,2.8v11.8h-5.3V130.1z"/>
    <path id="XMLID_90_" d="M403.2,162.2v-3.4l1.9,0.1c3.2,0.2,3.6-1.2,3.9-1.8l0.3-1l-7.2-17.9h5.2l4.5,12.1l4.5-12.1h5.1l-7.5,18.7
		c-1.9,4.8-4.2,5.8-7.1,5.8C404.2,162.8,403.2,162.2,403.2,162.2z"/>
  </g>
  <path id="XMLID_17_" d="M55.1,109.4l44.1-94.6h2.6l44.5,94.6h-2.8L129.1,79H71.8l-14.1,30.4H55.1z M73.2,76.5H128l-27.4-58.8
	L73.2,76.5z"/>
  <path id="XMLID_20_" d="M209.1,14.8v2.4h-36.2v92.2h-2.4V17.2h-36.1v-2.4H209.1z"/>
  <path id="XMLID_26_" d="M301.4,109.4V14.8h2.4v94.6H301.4z"/>
  <path id="XMLID_28_" d="M403.5,95.5c-8.6,8.6-20.4,13.9-33.6,13.9c-26.1,0-47.3-21.2-47.3-47.3s21.2-47.3,47.3-47.3
	c13,0,24.7,5.2,33.3,13.6l-1.8,1.6c-8.1-7.9-19.2-12.9-31.5-12.9C345,17.1,325,37.2,325,62s20.1,44.9,44.9,44.9
	c12.4,0,23.7-5.2,31.8-13.4L403.5,95.5z"/>
  <path id="XMLID_30_" d="M419,109.4V14.8h2.4v94.6H419z"/>
  <path id="XMLID_32_" d="M435,109.4l44.2-94.6h2.7l44.5,94.6h-2.8L509.2,79h-57.3l-14.1,30.4H435z M453.2,76.5H508l-27.4-58.8
	L453.2,76.5z"/>
  <path id="XMLID_35_" d="M572.7,107h56.7v2.4h-59V14.8h2.4L572.7,107L572.7,107z"/>
  <path id="XMLID_39_" d="M669,14.8c26.2,0,47.4,21.1,47.4,47.3s-21.2,47.3-47.4,47.3s-47.3-21.1-47.3-47.3S642.8,14.8,669,14.8z
	 M669,107c25,0,45-19.9,45-44.9s-20.1-44.9-45-44.9s-44.9,19.9-44.9,44.9S644.1,107,669,107z"/>
  <path id="XMLID_47_" d="M924.9,39.2c0-12.1-9.7-21.8-21.8-21.8s-21.8,9.7-21.8,21.8S891,61,903.1,61c13.4,0,24.2,10.9,24.2,24.2
	c0,13.4-10.8,24.2-24.2,24.2s-24.2-11-24.2-24.4h2.4c0,12.1,9.7,22,21.8,22s21.8-9.7,21.8-21.8s-9.7-21.9-21.8-21.9
	c-13.4,0-24.2-10.9-24.2-24.2s10.8-24.3,24.2-24.3s24.2,11,24.2,24.4H924.9z"/>
  <g id="XMLID_11_">
    <path id="XMLID_12_" class="st0" d="M33.8,68.3c13.9-0.1,25.4-11.4,25.4-25.5S47.7,17.2,33.6,17.2H9v51.1H33.8L33.8,68.3z"/>
    <path id="XMLID_49_" d="M33.8,14.8h-32v2.4h2.4H9h24.5c14.1,0,25.6,11.5,25.6,25.6S47.6,68.2,33.8,68.3H9H4.2H1.8v41.1h2.4V70.7
		h29.5c15.4,0,28-12.5,28-27.9S49.2,14.8,33.8,14.8z"/>
  </g>
  <g id="XMLID_14_">
    <path id="XMLID_15_" class="st0" d="M253.9,68.3c13.9-0.1,25.4-11.4,25.4-25.5c0-14-11.4-25.5-25.4-25.6h-26.2v51.1H253.9
		L253.9,68.3z"/>
    <path id="XMLID_16_" d="M281.9,42.8c0-15.4-12.6-28-28-28H222v2.4h2.4h3.3h26.2c14,0.1,25.4,11.6,25.4,25.6
		c0,14.1-11.5,25.4-25.4,25.5h-26.2h-3.3H222v41.1h2.4V70.7h27l22.3,38.7h2.9l-22.3-38.7C269.5,70.4,281.9,58.1,281.9,42.8z"/>
  </g>
  <g id="XMLID_25_">
    <path id="XMLID_50_" class="st0" d="M763.3,68.3c13.9-0.1,25.4-11.4,25.4-25.5s-11.5-25.6-25.6-25.6H737v51.1H763.3L763.3,68.3z"/>
    <path id="XMLID_51_" d="M763.3,14.8h-31.9v2.4h2.4h3.2h26.1c14.1,0,25.6,11.5,25.6,25.6s-11.5,25.4-25.4,25.5h-26.4h-3.2h-2.4v41.1
		h2.4V70.7h29.5c15.4,0,28-12.5,28-27.9S778.7,14.8,763.3,14.8z"/>
  </g>
  <polygon id="XMLID_44_" points="811.3,17.3 865.6,17.3 865.6,14.8 806.7,14.8 806.7,17.3 809.1,17.3 "/>
  <polygon id="XMLID_52_" points="804.7,109.4 863.6,109.4 863.6,106.9 807.1,106.9 807.1,106.9 804.7,106.9 "/>
  <polygon id="XMLID_24_" points="811.3,65.4 865.6,65.4 865.6,62.9 806.7,62.9 806.7,65.4 809.1,65.4 "/>
  <g class="st1">
    <path d="M1056.1,77.6c0-2.4-0.6-4.5-1.7-6.2s-2.9-3.2-5.4-4.5c-2.4-1.3-6.1-2.6-11-4c-4.9-1.4-8.6-2.7-11.1-4.1
		c-2.5-1.4-4.3-3-5.4-4.8c-1.1-1.8-1.7-4-1.7-6.6c0-4.1,1.7-7.5,5.2-10.1c3.5-2.6,8-3.8,13.6-3.8c3.6,0,6.9,0.7,9.8,2.1
		c2.9,1.4,5.1,3.4,6.7,6c1.6,2.6,2.4,5.5,2.4,8.7h-2.1c0-4.2-1.6-7.8-4.7-10.5s-7.2-4.2-12.1-4.2c-5,0-9,1.1-12.1,3.3
		s-4.6,5-4.6,8.4c0,3.4,1.3,6.2,4,8.2c2.7,2.1,7.1,3.9,13.3,5.5s10.7,3.5,13.6,5.6c3.6,2.7,5.5,6.3,5.5,10.8c0,2.9-0.8,5.4-2.4,7.6
		c-1.6,2.2-3.9,3.9-6.9,5.1s-6.3,1.8-10,1.8c-4.1,0-7.8-0.7-11.1-2.1s-5.7-3.3-7.4-5.8c-1.7-2.5-2.5-5.5-2.5-8.9h2.1
		c0,4.7,1.7,8.3,5.2,10.9c3.5,2.6,8,3.9,13.6,3.9c5,0,9.1-1.1,12.3-3.4C1054.4,84.2,1056.1,81.2,1056.1,77.6z"/>
    <path d="M1069.3,91.2V34.3h15c6.1,0,10.7,1.2,13.9,3.7c3.1,2.5,4.7,6.1,4.7,10.8c0,3.4-0.9,6.3-2.6,8.6c-1.7,2.3-4.2,3.7-7.4,4.3
		c3.6,0.5,6.6,2,9,4.7c2.4,2.6,3.6,5.7,3.6,9.1c0,4.8-1.6,8.7-4.7,11.5c-3.1,2.8-7.5,4.2-13.1,4.2H1069.3z M1071.4,61h13.6
		c5.2,0,9.1-1,11.7-3c2.6-2,3.9-5.1,3.9-9.3c0-8.1-5.3-12.2-16-12.3h-13.3V61z M1071.4,63.1v26h16.4c4.8,0,8.6-1.2,11.3-3.6
		c2.7-2.4,4.1-5.7,4.1-9.9c0-3.6-1.3-6.6-4-8.9c-2.7-2.3-6.2-3.5-10.7-3.6H1071.4z"/>
    <path d="M1156.2,73.8c-0.5,5.9-2.6,10.4-6.1,13.5c-3.5,3.1-8.2,4.7-14.2,4.7c-4,0-7.6-1-10.7-3.1c-3.1-2-5.5-4.9-7.2-8.7
		c-1.7-3.8-2.6-8.1-2.6-12.9v-8.8c0-4.8,0.8-9.2,2.5-13c1.7-3.8,4.1-6.8,7.2-8.8c3.1-2.1,6.7-3.1,10.8-3.1c5.9,0,10.7,1.6,14.2,4.7
		s5.5,7.6,6.1,13.4h-2.1c-0.6-5.2-2.4-9.2-5.5-11.9c-3-2.7-7.3-4.1-12.7-4.1c-5.5,0-10,2.1-13.4,6.2c-3.4,4.2-5.1,9.8-5.1,16.8V67
		c0,4.5,0.8,8.4,2.3,11.9c1.5,3.5,3.7,6.1,6.5,8.1c2.8,1.9,6.1,2.9,9.7,2.9c5.4,0,9.7-1.3,12.8-4c3.1-2.7,4.9-6.7,5.4-12.1H1156.2z"
    />
  </g>
  <polygon id="XMLID_00000036932833932114539970000015565664652966963382_" points="973.3,65.4 985,65.4 985,62.9 972.3,62.9
	972.3,65.4 972.9,65.4 "/>
</svg>

);
}

export default SvgComponent;
